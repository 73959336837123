

.form {
box-sizing: border-box;

position: relative;
width: 98%;
margin-left: 1%;
height: 96vh;
background-color: rgb(248, 248, 248);

}

.form .personal{
height: auto;
border: 2px solid red;
width: 100%;
padding-bottom: 1%;
margin-bottom: 1%;
}
.form .personal li{
width: auto;

padding-right: 3%;
padding-left: 3%;
text-align: center;
margin-top: 1%;
font-weight: 500;
color: rgb(15, 15, 15);
font-size: 18px;
margin-left: 3%;
line-height: 40px;
height: auto;
display: inline-block;
}
.form .personal li span{
color: rgb(247, 18, 86);
font-size: 22px;
}

 .form h2{
 padding-top: 2%;
text-align: center;
font-size: 20px;
color: rgb(63, 63, 63);
}



 form li{
width: 350px;

height: auto;
float: left;
margin: 1% 0% 0% 5%;
list-style: none;
}
 form li h4{
padding-left: 2%;
color: rgb(101, 101, 101);
}
 form li h4 span{
color: red;}

 form li input{
width: 98%;
box-sizing: border-box;
margin-left: 2%;
font-size: 20px;
outline: none;
padding-left: 4%;
height: 40px;
}

 form li select{
width: 98%;
box-sizing: border-box;
margin-left: 2%;
font-size: 20px;
outline: none;
padding-left: 2%;
height: 40px;
}
 form li select option{
font-size: 25px;
text-align: center;
}


 form li  select:focus > option:checked { 
    background: rgb(240, 240, 240) !important;
    color: rgb(255, 166, 0) !important;
}


 form .btn{
width: 100%;
clear: both;

}
 form .btn #submit{
width: auto;
margin-top: 5%;
margin-left: 4%;
margin-right: 2%;
padding-left: 2%;
padding-right: 2%;
margin-bottom: 5%;
height: auto;
background-color: rgb(251, 150, 34);
padding-top: 1%;
padding-bottom: 1%;
font-size: 23px;
border-radius: 5px;
color: white;
border: none;
text-align: center;
cursor: pointer;
transition:all 0.4s;
}

 .form  #back{
width: auto;
position: absolute;
bottom: 0%;
right: 10%;
padding-left: 2%;
padding-right: 2%;

height: auto;
background-color: rgb(4, 39, 181);
padding-top: 1%;
padding-bottom: 1%;
font-size: 23px;
border-radius: 5px;
color: white;
border: none;
text-align: center;
cursor: pointer;
transition:all 0.4s;
}

#month{
padding-right: 1%;
color: rgb(191, 27, 79);
padding-left: 1%;
}