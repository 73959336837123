@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');
body{

position: relative;
top: 0;
left: 0;


}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.container1 {
padding-top: 2%;
padding-bottom: 2%;
width: 100%;
height: auto;
background: linear-gradient(90deg, #C7C5F4, #776BCC);	
overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
 
}

.screen1 {		
	background: linear-gradient(90deg, #5D54A4, #7C78B8);		
	position: relative;	
	height: auto;
	width: 360px;	
	box-shadow: 0px 0px 24px #5C5696;
}

.screen__content1 {
	z-index: 1;
	position: relative;	
	height: 100%;
}

.screen__background1 {		
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);	
}

.screen__background__shape7 {
	transform: rotate(45deg);
	position: absolute;
}

.screen__background__shape22 {
	height: 1120px;
	width: 520px;
	background: #FFF;	
	top: -30px;
	right: 120px;	
	border-radius: 0 72px 0 0;
}

.screen__background__shape8 {
	height: 220px;
	width: 220px;
	background: #6C63AC;	
	top: -172px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape9 {
	height: 540px;
	width: 190px;
	background: linear-gradient(270deg, #5D54A4, #6A679E);
	top: -24px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape10 {
	height: 400px;
	width: 200px;
	background: #7E7BB9;	
	top: 420px;
	right: 50px;	
	border-radius: 60px;
}

.login1 {
	width: 320px;
	padding: 30px;
	padding-top: 96px;
}

.login__field1 {
	padding: 20px 10px;	
	position: relative;	
}
.login__field1 input{
padding-left: 45px;
font-size: 15px;

}

 #date{
text-align: center;
color: rgb(86, 86, 86);	
}
.login__field1 select{

font-size: 15px;
border: none;
outline: none;

color: rgb(100, 100, 100);
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	font-weight: 700;
	width: 92%;
	transition: .2s;

}
.login__field1 select option{
font-size: 20px;
text-align: center;
height: 900px;
}

.login__icon1 {
	position: absolute;
	top: 30px;
	color: #7875B5;
}

.login__input1 {
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 24px;
	font-weight: 700;
	width: 75%;
	transition: .2s;
}

.login__input1:active,
.login__input1:focus,
.login__input1:hover {
	outline: none;
	border-bottom-color: #6A679E;
}

.login__submit1 {
	background: #fff;
	font-size: 14px;
	margin-top: 40px;
	padding: 16px 20px;
	border-radius: 26px;
	border: 1px solid #D4D3E8;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
	color: #4C489D;
	box-shadow: 0px 2px 2px #5C5696;
	cursor: pointer;
	transition: .2s;
}

.login__submit1:active,
.login__submit1:focus,
.login__submit1:hover {
	border-color: #6A679E;
	outline: none;
}

.button__icon1 {
	font-size: 24px;
	margin-left: auto;
	color: #7875B5;
}

.social-login1 a{	  color: #fff;

  text-decoration: none;
  
  }
.social-login1 {	
	position: absolute;

	height: 140px;

	width: 160px;
	text-align: center;
	bottom: 0px;
	right: 0px;
	
}

.social-icons1 {
	display: flex;
	align-items: center;
	justify-content: center;
}

.social-login__icon1 {
	padding: 20px 10px;
	color: #fff;
	text-decoration: none;	
	text-shadow: 0px 0px 8px #7875B5;
}

.social-login__icon1:hover {
	transform: scale(1.5);	
}


