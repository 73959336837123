.personal{
box-sizing: border-box;
width: 93%;
margin-top: 2%;
height: 900px;
overflow-y: auto;
overflow-x: hidden;
margin-left: 3%;
box-shadow: 0px 0px 12px 2px rgb(245, 245, 245);
background-color: rgb(248, 248, 248);
}
.personal h5{
font-size: 25px;

text-align: center;
color: red;
padding-top: 2%;
}
.personal .img{
text-align: center;
margin-top: 0;
width: 100%;
height: 300px;
}
.personal #profile{

width: 300px;
}

.personal .personaldata{
height: auto;
width: 100%;
margin-top: 4%;
list-style: none;
}

.personal .personaldata li{
height: 40px;
text-transform: uppercase;
width: 100%;
margin-top: 2%;
user-select: none;
box-sizing: border-box;
padding-left: 10%;
font-weight: 550;
font-size: 30px;
color: rgb(38, 38, 38);
}
.personal .personaldata li span{
float: right;
color: red;
padding-right: 10%;
font-weight: 600;}
