.addStudent {
width: 100%;
height: auto;
display: flex;
}


.addStudent .rightSideStudent{
background-color: rgb(243, 243, 243);
width: 81%;
}
#userName{
color: red;
}
.addStudent .rightSideStudent h6{
width: 100%;
margin-top: 2%;
font-size: 22px;
color: rgb(54, 54, 54);
box-sizing: border-box;
padding-left: 90px;
text-transform: uppercase;
}

.addStudent .rightSideStudent h3{
width: 100%;
margin-top: 2%;
font-size: 16px;
color: rgb(248, 0, 0);
box-sizing: border-box;
text-align: center;
text-transform: uppercase;
}
.addStudent .addDataForm{
width: 94%;
margin-left: 3%;
margin-top: 2%;
height: auto;
padding-bottom: 5%;
background-color: #fff;
}
.addStudent .addDataForm h1{
padding-left: 3%;
padding-top: 2%;
font-size: 25px;
color: rgb(75, 75, 75);
}

.addStudent .addDataForm form li{
width: 300px;

height: auto;
float: left;
margin: 1% 0% 0% 3%;
list-style: none;
}
.addStudent .addDataForm form li h4{
padding-left: 2%;
color: rgb(101, 101, 101);
}
.addStudent .addDataForm form li h4 span{
color: red;}

.addStudent .addDataForm form li input{
width: 98%;
box-sizing: border-box;
margin-left: 2%;
font-size: 20px;
outline: none;
padding-left: 4%;
height: 40px;
}

.addStudent .addDataForm form li select{
width: 98%;
box-sizing: border-box;
margin-left: 2%;
font-size: 20px;
outline: none;
padding-left: 2%;
height: 40px;
}
.addStudent .addDataForm form li select option{
font-size: 25px;
text-align: center;
}


.addStudent .addDataForm form li  select:focus > option:checked { 
    background: rgb(240, 240, 240) !important;
    color: rgb(255, 166, 0) !important;
}


.addStudent .addDataForm form .btn{
width: 100%;
clear: both;

}
.addStudent .addDataForm form .btn #submit{
width: auto;
margin-top: 5%;
margin-left: 4%;
margin-right: 2%;
padding-left: 2%;
padding-right: 2%;
height: auto;
background-color: rgb(251, 150, 34);
padding-top: 1%;
padding-bottom: 1%;
font-size: 23px;
border-radius: 5px;
color: white;
border: none;
text-align: center;
cursor: pointer;
transition:all 0.4s;
}


.addStudent .addDataForm form .btn #reset{
width: 150px;
margin-top: 5%;
margin-left: 0%;
margin-right: 5%;
height: auto;
background-color: rgb(7, 61, 169);
padding-top: 1%;
padding-bottom: 1%;
font-size: 23px;
border-radius: 5px;
color: white;
border: none;
text-align: center;
cursor: pointer;
transition:all 0.4s;
}
.addStudent .addDataForm form .btn #submit:hover{
background-color: rgb(7, 61, 169);

}
.addStudent .addDataForm form .btn #reset:hover{
background-color: rgb(251, 150, 34);

}
