.searchFilter{
width: 100%;
height: 140px;
margin-bottom: 5%;
overflow: hidden;
}
.searchFilter h4{
padding-left: 3%;
font-size: 18px;
color: rgb(39, 39, 39);
}
.searchFilter .searchBar{
width: 100%;
padding-left: 3%;
height: auto;
}
.searchFilter .searchBar input{
height: 40px;
margin-left: 1%;
border: none;
outline: none;
font-size: 17px;
background-color: rgb(239, 239, 239);
box-sizing: border-box;
padding-left: 2%;
color: rgb(74, 74, 74);
width: 250px;
}
.searchFilter .searchBar button{
margin-left: 2%;
width: 150px;
height: 40px;
font-size: 16px;
color: white;
border: none;
cursor: pointer;
font-weight: 600;
border-radius: 5px;
background-color: rgb(0 165 81);
transition: all 0.4s;
}
.searchFilter .searchBar button:hover{
border: 1px solid black;
background: none;
color: red;
box-shadow: 0px 0px 12px 1px rgb(148, 148, 148);
}