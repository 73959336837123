/* left side bar */
.left-section{
width: 19%;
height: auto;
background: rgb(255, 255, 255);
}
.left-section .logo-section{
	background: linear-gradient(90deg, #ffffff, #ffffff);	
height: 70px;

}
.left-section a{text-decoration: none;}
.left-section .logo-section .logo{
position: relative;
width: 80%;
height: 70px;

float: left;
}
.left-section .logo-section  .logo #logo{
position: absolute;
top: 4%;
left: 20%;
width: 155px;
}

.left-section .links{
width: 100%;
height: auto;

}

.left-section .links li{
display: block;
width: 100%;
box-sizing: border-box;
position: relative;
user-select: none;
padding-left: 70px;
padding-top: 10px;
height:auto;
color: rgb(242, 5, 5);
line-height: 50px;
cursor: pointer;
font-weight: 600;
overflow: hidden;
border: 1px solid rgb(205, 205, 205);
}

.left-section .links li #outIcon{
font-size: 30px;
position: absolute;
top: 30%;
left: 5%;
color: rgb(245, 2, 2);
}
.left-section .links li #arrows{
position: absolute;
top: 30%;
right: 5%;
font-size: 25px;
color: rgb(106, 106, 106);

}


.left-section .links  ul{
display: none;
position: relative;
top: 0;
cursor: pointer;
left: 0;
box-sizing: border-box;
width: 100%;
padding: 0px;
margin: 0;
border: 1px solid rgb(222, 55, 55);
height: auto;
}
.left-section .links ul li{
display: block;
width: 100%;
box-sizing: border-box;
position: relative;
padding-left: 55px;
padding-top: 0px;
padding-bottom: 2%;
height:auto;
color:rgb(47 182 113);
line-height: 40px;
cursor: pointer;
overflow: hidden;
font-size: 14px;
border: none;

transition: all 0.3s;
}
.left-section .links ul li:hover{
	background: rgb(244, 244, 244);
}

.left-section .links ul li #inIcon{
font-size:20px;
position: absolute;
top: 30%;
left: 6%;
color: rgb(47 182 113);
}




