 .top-bar{
display: flex;
width: 100%;
height: 70px;
background-color: #fff;
box-shadow: 0px 0px 4px 0px rgb(84, 84, 84);

}
 .top-bar .search{
position: relative;
width: 60%;
float: left;
padding-top: 0.5%;
} .top-bar .search i{


padding-left: 6%;
font-size: 25px;
color: rgb(142, 142, 142);
}
 .top-bar .search input{
border: none;
outline: none;
padding-left: 5%;
color: rgb(142, 142, 142);

width: 90%;
font-size: 24px;
margin-top: 5px;
height: 50px;
}

input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
/* users top bar */ .top-bar .user{
width: 40%;

display: flex   ;

}
 .top-bar .user .username{
cursor: pointer;
width: 78%;
height: 50px;
text-align: center;
} .top-bar .user .username h1{
margin-top: 5%;
float: right;
margin-right: 3%;
font-size: 15px;
color: rgb(19, 19, 19);
} .top-bar .user .username p{
margin-top: -2%;
clear: both;
box-sizing: border-box;
font-weight: 500;
float: right;
margin-right: 3%;
font-size: 15px;
color: rgb(105, 105, 105);

} .top-bar .user i{

width: 6%;
line-height: 50px;
color: rgb(96, 96, 96);
} .top-bar .user img{
width: 65px;
padding-top: 5px;
height: 60px;

    }
