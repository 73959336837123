.statuss{
position: relative;
margin-bottom: 1%;
width: 93%;
padding-bottom: 2%;
height: auto;
border-bottom: 3px solid rgb(6, 194, 34);;
}
.statuss #pay{

width: 150px;
height: 50px;
font-size: 20px;
color: white;
border: none;
cursor: pointer;
border-radius: 30px;
background-color: rgb(6, 194, 34);
transition: all 0.4s;

}

.statuss #notPay{
margin-left: 5%;
width: 150px;
height: 50px;
font-size: 20px;
border: none;
cursor: pointer;
border-radius: 30px;
color: rgb(34, 34, 34);
background-color: rgb(213, 213, 213);
transition: all 0.4s;
}



.statuss #notPay:hover{
color: white;
background-color: rgb(215, 5, 5);
}


.statuss #pay:hover{
color: white !important;
background-color: rgb(6, 194, 34) !important;
}
#pays{
width: 93%;
padding-bottom: 1%;
color: rgb(6, 194, 34);
border-bottom: 3px solid rgb(6, 194, 34);
}

#feeTotal{
position: absolute;
top: 0;
height: auto;
line-height: 50px;
right:0%;
text-align: right;
height:50px;
font-size: 19px;
color: rgb(72, 72, 72);
width: 350px;
}
#feeTotal #amount{
padding-left: 3%;
padding-right: 2%;
padding-bottom: 2%;
font-size: 20px;
font-weight: 600;
color: rgb(0, 69, 119);
}


#len{
position: absolute;
top: 0;
font-size: 16px;
right : 8%;
color: rgb(81, 81, 81);
text-align: center;

}